'use client'
import './Hero.scss'
import MainImg from "@/components/elements/MainImg/MainImg";
import {toggleHireUsModal} from "@/helpers/toggleModals";

const Hero = () => {

    return (
        <section className='hero'>
            <div className="hero__container">
                <div className="hero__text-box">
                    <h1 className='hero__title'>UNLOCK YOUR <span className='accent'>SUCCESS</span></h1>
                    <p className='hero__text'>Scale your business by attracting <br/>
                        new users in different countries.</p>
                    <button id="heroBtn" onClick={toggleHireUsModal} className='button hero__btn'>Hire us to solve the riddle</button>
                </div>
                <div className="hero__date-box section__wrapper">
                    <span>Since 2014</span>
                    <span>52°10'31.7"20°59'59.1"</span>
                </div>
                <div className="hero__img">
                    <MainImg/>
                </div>
            </div>
        </section>
    )
}
export default Hero