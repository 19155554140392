import "./SocialList.scss"
import Link from "next/link";
import Image from "next/image"

const SocialList = () => {
  return (
      <ul className="social__list">
          <li><Link href="https://www.facebook.com/adkey.agency/" target={"_blank"} rel="noopener noreferrer"><Image  id="fbLink" src="/images/fb.svg" alt="facebook" width={32} height={32}/></Link></li>
          <li><Link href="https://www.instagram.com/adkey.agency/" target={"_blank"} rel="noopener noreferrer"><Image  id="instaLink" src="/images/insta.svg" alt="instagram" width={32} height={32}/></Link></li>
          <li><Link href="https://www.linkedin.com/company/adkeyagency/" target={"_blank"} rel="noopener noreferrer"><Image  id="linkedinLink" src="/images/linkedin.svg" alt="likedin" width={32} height={32}/></Link></li>
          <li><Link href="https://www.youtube.com/watch?v=YepJe2N66cs" target={"_blank"} rel="noopener noreferrer"><Image id="youtubeLink" src="/images/youtube.svg" alt="youtube" width={32} height={32}/></Link></li>
      </ul>
  )
}
export default SocialList