"use client"
import FormHire from "@/components/modules/Form/Form";
import "./HireUs.scss"
import Image from "next/image";
import {toggleHireUsModal} from "@/helpers/toggleModals";
import MainImg from "@/components/elements/MainImg/MainImg";
const HireUs = () => {
  return (
      <div className="hireUs__backdrop" id="hireUs">
          <div className="hireUs__layout">
              <button className='hireUs__close' onClick={toggleHireUsModal}>
                  <Image id="hireUsModalClose" src='/images/close.svg' alt='close icon' width={32} height={32}/>
              </button>
            <h3 className="hireUs__title">Write us</h3>
              <div className="HireUs__form-box">
                  <FormHire id="formOnModal"/>
              </div>
              <div className="HireUs__img--mob">
                  <MainImg/>
              </div>
              <Image className="HireUs__img--tab" src="/images/hireus-img.svg" alt="modal vector" width={560} height={186}/>

          </div>
      </div>
  )
}
export default HireUs