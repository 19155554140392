'use client'
import './MainImg.scss'
import Image from "next/image";
import {useEffect, useState} from "react";

const MainImg = () => {
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const windowWidth = window.innerWidth
            const imgRef = document.querySelector(".main-img__icon");
            const imgWidth = parseInt(window.getComputedStyle(imgRef).getPropertyValue("width"));
            const diff = imgWidth - windowWidth;
            const setY = window.scrollY > diff ? diff : Number(window.scrollY) ;
            setScrollY(setY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
            <div className='main-img__wrapper'>
                <div className='main-img__box' style={{transform: `translateX(-${scrollY}px)`}}>
                    <Image className="main-img__icon" src='/images/main__image.svg' alt='vector image' width={2220} height={248}/>
                </div>
            </div>
    )
}
export default MainImg