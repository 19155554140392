"use client"
import Image from "next/image";
import VerticalsList from "@/components/modules/VerticalsList/VerticalsList";
import VerticalsListMob from "@/components/modules/VerticalsList/VerticalsListMob";
import useWindowWidth from "@/hooks/useWindowWidth";
import {useEffect, useState} from "react";
import './Verticals.scss'


const Verticals = () => {
    const [isClient, setIsClient] = useState(false)
    const windowWidth = useWindowWidth();
    useEffect(() => {
        setIsClient(true)
    }, []);
    return (
        <section className='verticals section' id="verticals">
            <div className="section__wrapper">
                <div className="container verticals__container">
                    <h2 className="section__title">Our top <span>verticals</span></h2>
                    <div className='verticals__text'>
                        <Image src='/images/globus.svg' alt='globus' width={24} height={24}/>
                        <span>Scale your business by attracting new users in different countries.</span>
                    </div>

                </div>
                {isClient && (windowWidth < 820 ?  <VerticalsListMob /> : <VerticalsList /> )}
            </div>
        </section>
    )
}
export default Verticals