'use client'
import './VerticalList.scss';
import Image from "next/image";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useLayoutEffect, useRef} from "react";

gsap.registerPlugin(ScrollTrigger)
const VerticalsList = () => {
    const listRef = useRef(null);

    useLayoutEffect(() => {
        const header = document.querySelector(".header");
        const list = document.querySelector(".verticals__list")
        function getScrollAmount() {
            let listWidth = list.scrollWidth;
            return -((listWidth - (window.innerWidth * 0.71)) * 1.7);
        }
        const tween = gsap.to(list, {
            x: getScrollAmount,
            duration: 5,
            ease: "none"
        });
        ScrollTrigger.create({
            trigger: ".verticals",
            start: function () {
                const start = header.offsetHeight;
                return `top ${start}`;
            },
            end: () => `+=${getScrollAmount() * -1}`,
            pin: true,
            pinSpacing: true,
            animation: tween,
            invalidateOnRefresh: true,
            scrub: true,
        });
    }, []);

    return (
        <div
            className={'verticals__list'}
            ref={listRef}
        >
            <div className="verticals__item ">
                <div>
                    <h3 className='verticals__title'>Binary options / crypto</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box odd">
                    <Image className='verticals__icon odd' src='/images/crypto.svg' alt='crypto' width={180}
                           height={180}/>
                </div>
            </div>
            <div className="verticals__item ">
                <div>
                    <h3 className='verticals__title'>Dating</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box even">
                    <Image className='verticals__icon even' src='/images/dating.svg' alt='dating' width={200}
                           height={92}/>
                </div>
            </div>
            <div className="verticals__item ">
                <div>
                    <h3 className='verticals__title'>Betting</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box odd">
                    <Image className='verticals__icon odd' src='/images/betting.svg' alt='betting' width={180}
                           height={180}/>
                </div>
            </div>
            <div className="verticals__item ">
                <div>
                    <h3 className='verticals__title'>Fintech</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box even">
                    <Image className='verticals__icon even' src='/images/fintech.svg' alt='fintech' width={200}
                           height={114}/>
                </div>
            </div>
            <div className="verticals__item">
                <div>
                    <h3 className='verticals__title'>Mobile apps</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box odd">
                    <Image className='verticals__icon odd' src='/images/mob-apps.svg' alt='Mobile apps' width={180}
                           height={180}/>
                </div>
            </div>
            <div className="verticals__item ">
                <div>
                    <h3 className='verticals__title'>gambling</h3>
                    <p className="verticals__date">April - October 2021</p>
                </div>
                <div className="verticals__img-box even">
                    <Image className='verticals__icon even' src='/images/gambling.svg' alt='gambling' width={200}
                           height={110}/>
                </div>
            </div>
        </div>

    )
}
export default VerticalsList