"use client"
import "./WriteUs.scss"
import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import Image from "next/image";
import SocialList from "@/components/modules/SocialList/SocialList";
import FormHire from "@/components/modules/Form/Form";

const WriteUs = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {ref, inView} = useInView({
        threshold: 0.5, // Попадание в видимую область, когда 50% элемента видно
    });
    useEffect(() => {
        if (inView) {
            setIsOpen(true);
        }
         else {
            setIsOpen(false)
        }
    }, [inView]);
    return (
        <section className="write-us section">
            <div className={`write-us__logo-box ${isOpen ? 'isOpen' : ''}`} ref={ref}>
                <div className="write-us__logo--left">
                    <Image className="" src="/images/logo-part-left.svg" alt="logo left part" width={60}
                           height={120}/>
                </div>
                <div className="write-us__logo--right">
                    <Image className="" src="/images/logo-part-right.svg" alt="logo right part"
                           width={60} height={120}/>
                </div>
            </div>
            <h2 className="section__title center write-us__title">Write us</h2>
            <div className="write-us__form">
                <FormHire id="formOnMainPage"/>
            </div>
            <SocialList/>
        </section>
    )
}
export default WriteUs