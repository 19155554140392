"use client"
import Image from "next/image";

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button className="footer__btn-up" onClick={scrollToTop}><Image id="btnScrollToTop" src='/images/arrow.svg' alt='arrow' width={11} height={11}/></button>
    );
};

export default ScrollToTopButton;