"use client"
import "./Form.scss"

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {useEffect, useState} from "react";
import { usePathname } from 'next/navigation'
import {createDocAutoId} from "@/lib/firebase/firestore";



const FormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    emailPhone: Yup.string().matches(/^(?:\+?\d{1,3}[-\s]?)?\d{7,15}$|^\S+@\S+\.\S+$/, 'The field is filled incorrectly').required('Required'),
    website: Yup.string().matches(/^https?:\/\/\S+$/, 'URL is filled incorrectly').required('Required'),
});

const FormHire = ({type, id}) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const pathname = usePathname()
    useEffect(() => {
        setTimeout(() => {
            setIsSubmit(false)
        }, 20000)
    }, [isSubmit]);
    const handleSubmit = async (values, {setSubmitting}) => {
        const form = document.querySelector("#formOnModal")
        try {
            await createDocAutoId('hire-us',  values);
            await fetch("/api/send-mail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({form: "hire", data: values})});
            setIsSubmit(true);
            window.dataLayer.push ({
                'event': `submit_${id}_${pathname}`
            });
        } catch (e) {
            setIsSubmit(false)
        } finally {
            setSubmitting(false);
        }
    }
    return (
        isSubmit
            ? (<div className="form">
                <p className="form__message">Thank you! Your message has been sent. We will reply to you shortly.</p>
            </div>) :
            <Formik
                initialValues={{
                    name: '',
                    emailPhone: '',
                    company: '',
                    website: '',
                    comment: ''
                }}
                validationSchema={FormSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => (
                    <Form className="form" id={id}>
                        <div className="form__input-box">
                            <div className="input__requared">
                                <Field
                                    className={`form__input form__input--name ${errors.name && touched.name ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="name"
                                    placeholder="Your name"
                                />
                                <ErrorMessage name="name" component="p" className="form__error"/>
                            </div>
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.emailPhone && touched.emailPhone ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="emailPhone"
                                    placeholder="Your email or phone"
                                />
                                <ErrorMessage name="emailPhone" component="p" className="form__error"/>
                            </div>
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.website && touched.website ? 'form__input--error' : ''}`}
                                    type="url"
                                    name="website"
                                    placeholder="Website link"
                                />
                                <ErrorMessage name="website" component="p" className="form__error"/>
                            </div>
                            <Field
                                className="form__input"
                                name="company"
                                type="text"
                                placeholder="Company name (Optional)"
                            />
                            <Field
                                className="form__input"
                                name="comment"
                                type="text"
                                placeholder="Comment (Optional)"
                            />
                        </div>
                        <button
                            className={`button form__btn ${type === "hireUs" ? "form__btn--hireUs" : ""}`}
                            type="submit"
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                        >
                            {isSubmitting ? 'Sending...' : 'Send'}
                        </button>

                    </Form>
                )}
            </Formik>
    );
}

export default FormHire;