'use client'
import Image from "next/image";
import Flickity from 'react-flickity-component'
import './VerticalList.scss';
import {useEffect, useRef, useState} from "react";
import { useInView } from 'react-intersection-observer';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const VerticalsListMob = () => {
    const flickityRef = useRef(null);
    const [inViewRef, inView] = useInView({
        threshold: 1, // Проверяем, когда половина секции видима
    });

    useEffect(() => {
        const handleScroll = () => {
            if (inView) {
                // Если секция видима, прокручиваем карусель до конца
                flickityRef.current?.flkty?.next();
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [inView]);

    return (
        <div className="verticals__wrapper" ref={inViewRef}>
            <Flickity
                className={'verticals__list'} // default ''
                elementType={'div'}
                // options={{cellAlign}} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >
                <div className="verticals__item ">
                    <div className="">
                        <h3 className='verticals__title'>Binary options / crypto</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box odd">
                        <Image className='verticals__icon odd' src='/images/crypto.svg' alt='crypto' width={180}
                               height={180}/>
                    </div>
                </div>
                <div className="verticals__item ">
                    <div className="">
                        <h3 className='verticals__title'>Dating</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box even">
                        <Image className='verticals__icon even' src='/images/dating.svg' alt='dating' width={200}
                               height={92}/>
                    </div>
                </div>
                <div className="verticals__item ">
                    <div className="">
                        <h3 className='verticals__title'>Betting</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box odd">
                        <Image className='verticals__icon odd' src='/images/betting.svg' alt='betting' width={180}
                               height={180}/>
                    </div>
                </div>
                <div className="verticals__item ">
                    <div className="">
                        <h3 className='verticals__title'>Fintech</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box even">
                        <Image className='verticals__icon even' src='/images/fintech.svg' alt='fintech' width={200}
                               height={114}/>
                    </div>
                </div>
                <div className="verticals__item">
                    <div className="">
                        <h3 className='verticals__title'>Mobile apps</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box odd">
                        <Image className='verticals__icon odd' src='/images/mob-apps.svg' alt='Mobile apps' width={180}
                               height={180}/>
                    </div>
                </div>
                <div className="verticals__item ">
                    <div className="">
                        <h3 className='verticals__title'>gambling</h3>
                        <p className="verticals__date">April - October 2021</p>
                    </div>
                    <div className="verticals__img-box even">
                        <Image className='verticals__icon even' src='/images/gambling.svg' alt='gambling' width={200}
                               height={110}/>
                    </div>
                </div>
            </Flickity>
        </div>
    )
}
export default VerticalsListMob