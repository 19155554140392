"use client"
import { useEffect, useState } from "react";
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
    useEffect(() => {
        const windowWidthHandler = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", windowWidthHandler);

        return () => {
            window.removeEventListener("resize", windowWidthHandler);
        };
    }, []);

    return windowWidth;
};

export default useWindowWidth;