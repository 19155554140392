import {ref, uploadBytes, getDownloadURL} from "firebase/storage";

import {storage} from "@/lib/firebase/firebase";

export const uploadFileToFirebase = async (name, file) => {
    if (!file) return null;
    const storageRef = ref(storage, `cv/${name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};