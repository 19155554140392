"use client"

import "./Form.scss"
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {useEffect, useState} from "react";
import {createDocAutoId, getOneDoc} from "@/lib/firebase/firestore";
import {usePathname} from "next/navigation";
import {uploadFileToFirebase} from "@/lib/firebase/storage";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    emailPhone: Yup.string().matches(/^(?:\+?\d{1,3}[-\s]?)?\d{10}$|^\S+@\S+\.\S+$/, 'The field is filled incorrectly').required('Required'),
    media: Yup.string().url('The field is filled incorrectly').required('Required'),
    info: Yup.string().required('Required'),
    cv: Yup.string(),
})


const FormCV = ({type}) => {
    const pathname = usePathname()
    const [hrEmail, setHrEmail] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)

    const idDoc = pathname.slice(9)
    useEffect(() => {
        const getHrEmail = async () => {
            try {
                const vacancy = await getOneDoc('positions', idDoc)
                setHrEmail(vacancy.hr.email)
            } catch (e) {
                setHrEmail('')
            }
        }
        getHrEmail();
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setIsSubmit(false)
        }, 20000)
    }, [isSubmit]);

    const handleChangeFile = async (event, setFieldValue, validateField) => {
        try {
            const selectedFile = event.currentTarget.files[0];
            setFieldValue('file', selectedFile)
            setFieldValue('cv', selectedFile.name, false)
            validateField('cv')
        } catch (e) {
            console.log(e.message)
        }
    }

    const handleSubmit = async (values, {setSubmitting}) => {
        try {
            const valuesForSending = {...values};
            if (values.file) {
                const fileName = values.emailPhone + '-' + Date.now();
                const cvUrl = await uploadFileToFirebase(fileName, values.file)
                valuesForSending.file = cvUrl.toString();
            }
            console.log("valuesForSendingToDB", valuesForSending)
            const created = await createDocAutoId('cv', valuesForSending);
            await fetch("/api/send-mail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    form: "cv",
                    email: hrEmail,
                    data: {vacancy: idDoc.replace(/-/g, ' '), ...valuesForSending}
                })
            });
            setIsSubmit(true)
            window.dataLayer.push({
                'event': `submit_form_cv_${pathname} `
            });
        } catch (e) {
            setIsSubmit(false)
        } finally {
            setSubmitting(false);
        }
    }
    return (
        isSubmit
            ? <div className="form">
                <p className="form__message">
                    <span>
                        Thanks for your response. We have already sent it to the HR department
                        to examine it.
                        <br />
                        <br />
                        If you’re a match for any position at AdKey.Agency, you will definitely
                        be contacted.
                        <br />
                        <br />
                        In the meantime, follow the news on our{" "}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="https://www.instagram.com/adkey.agency/"
                        >
                          Instagram
                        </a>
                    </span>
                </p>
            </div> :
            <Formik
                initialValues={{
                    name: '',
                    emailPhone: '',
                    media: '',
                    info: '',
                    cv: '',
                    file: null,
                }}
                validationSchema={FormSchema}
                onSubmit={handleSubmit}
            >
                {({setFieldValue, validateField, isSubmitting, errors, touched, values}) => (
                    <Form className="form" id={type}>
                        <div className="form__input-box">
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.name && touched.name ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="name"
                                    placeholder="Your name"
                                />
                                <ErrorMessage name="name" component="p" className="form__error"/>
                            </div>
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.emailPhone && touched.emailPhone ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="emailPhone"
                                    placeholder="Your email or phone"
                                />
                                <ErrorMessage name="emailPhone" component="p" className="form__error"/>
                            </div>
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.media && touched.media ? 'form__input--error' : ''}`}
                                    type="url"
                                    name="media"
                                    placeholder="social media link"
                                />
                                <ErrorMessage name="media" component="p" className="form__error"/>
                            </div>
                            <div className="input__requared">
                                <Field
                                    className={`form__input ${errors.info && touched.info ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="info"
                                    placeholder="information about you"
                                />
                                <ErrorMessage name="info" component="p" className="form__error"/>

                            </div>
                            <div className="input__requared input__cv">
                                <Field
                                    className={`form__input ${errors.cv && touched.cv ? 'form__input--error' : ''}`}
                                    type="text"
                                    name="cv"
                                    placeholder="link to your cv"
                                    value={values.cv}
                                />
                                <ErrorMessage name="cv" component="p" className="form__error"/>
                                <span>OR</span>
                                <div className="upload-container">
                                    <label className="file-label">
                                        <input type="file" className="file-input" name="file" multiple
                                               accept={'application/pdf'}
                                               onChange={(event) => handleChangeFile(event, setFieldValue, validateField)}
                                        />
                                        <span>RESUME.PDF</span>
                                        <img src="/images/upload.svg" alt="Upload Icon"/>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <button
                            className={`button form__btn ${type === "hireUs" ? "form__btn--hireUs" : ""}`}
                            type="submit"
                            disabled={isSubmitting || Object.keys(errors).length !== 0}
                        >
                            {isSubmitting ? 'Sending...' : 'Send'}
                        </button>
                    </Form>
                )}
            </Formik>)
}

export default FormCV;
