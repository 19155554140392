"use client"
import './Map.scss'
import Image from "next/image"
import {useInView} from "react-intersection-observer";
import {useEffect, useRef} from "react";

const Map = () => {
    const {ref, inView} = useInView({
        threshold: 0.5,
    });
    const mapPointsRefs = Array.from({length: 7}, () => useRef(null));

    useEffect(() => {
        let delay = 0;
        if (inView) {
            mapPointsRefs.forEach((pointRef, index) => {
                const point = pointRef.current;
                if (point) {
                    setTimeout(() => {
                        point.classList.remove(`isHidden`);
                    }, delay);
                    delay += 100;
                }

            });
        } else {
            mapPointsRefs.forEach((pointRef, index) => {
                const point = pointRef.current;
                if (point) {
                    setTimeout(() => {
                        point.classList.add(`isHidden`);
                    }, delay);
                    delay += 100;
                }

            });
        }
    }, [inView]);

    return (
        <section className="map section">
            <div className="section__wrapper">
                <div className="container map__container">
                    <h2 className="section__title">Paying users <br/> aquired</h2>
                </div>
                <div ref={ref} className="map__box">
                    <Image className="map__img" src='/images/map/map1.svg' alt='map' width={343} height={220}/>
                    <ul className="map__list">
                        <li ref={mapPointsRefs[0]} className="map__item map__item--1 isHidden">
                            <span className="map__point map__point--1"></span>
                            <span className="map__country" style={{color: "#FF57DA"}}>USA+Canada</span>
                            <span className="map__value">120k+</span>
                        </li>
                        <li ref={mapPointsRefs[1]} className="map__item map__item--2 isHidden">
                            <span className="map__point map__point--2"></span>
                            <span className="map__country" style={{color: "#57A4FF"}}>Latin America</span>
                            <span className="map__value">40k+</span>
                        </li>
                        <li ref={mapPointsRefs[2]} className="map__item map__item--3 isHidden">
                            <span className="map__point map__point--3 "></span>
                            <span className="map__country" style={{color: "#FCCD03"}}>Europe</span>
                            <span className="map__value">350k+</span>
                        </li>
                        <li ref={mapPointsRefs[3]} className="map__item map__item--4 isHidden">
                            <span className="map__point map__point--4"></span>
                            <span className="map__country" style={{color: "#FF5757"}}>Africa+Mid East</span>
                            <span className="map__value">230k+</span>
                        </li>
                        <li ref={mapPointsRefs[4]} className="map__item map__item--5 isHidden">
                            <span className="map__point map__point--5"></span>
                            <span className="map__country" style={{color: "#57FF5E"}}>CIS</span>
                            <span className="map__value">200k+</span>
                        </li>
                        <li ref={mapPointsRefs[5]} className="map__item map__item--6 isHidden">
                            <span className="map__point map__point--6"></span>
                            <span className="map__country" style={{color: "#7957FF"}}>Asia</span>
                            <span className="map__value">230k+</span>
                        </li>
                        <li ref={mapPointsRefs[6]} className="map__item map__item--7 isHidden">
                            <span className="map__point map__point--7"></span>
                            <span className="map__country" style={{color: "#57FFD7"}}>Australia</span>
                            <span className="map__value">25k+</span>
                        </li>

                    </ul>
                </div>
            </div>
        </section>
    )
}
export default Map