import {collection, doc, getDoc, addDoc, setDoc, deleteDoc, getDocs, updateDoc, serverTimestamp, orderBy ,query} from "firebase/firestore";
import {db} from './firebase'

export async function createDoc(collection, docId, data) {
    try {
        const docRef = doc(db, collection, docId);
        await setDoc(docRef, {...data, created: serverTimestamp()});
        return('Document added or updated successfully!');
    } catch (e) {
        return (e.message)
    }
}
export async function createDocAutoId(collectionName, data) {
    try {
        const docRef = await addDoc(collection(db, collectionName), {...data, timestamp: serverTimestamp()});
        // await addDoc(docRef, data);
        return ({id: docRef.id});
    } catch (e) {
        return (e.message)
    }
}
export async function editDoc( collection, docId, data) {
    try {
        const docRef = doc(db, collection, docId);
        await updateDoc(docRef, {...data, updated: serverTimestamp()});
        return "Doc updated"
    } catch (e) {
        console.log(e.message)
        return (e.message)
    }
}
export async function removeDoc(collection, docId) {
    try {
        const docRef = doc(db, collection, docId);
        await deleteDoc(docRef);
        return('Document deleted successfully!');
    } catch (e) {
        return (e.message)
    }
}

export async function getCollection (collectionName){
    const querySnapshot = await getDocs(collection(db, collectionName));
    let data = [];
    querySnapshot.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()});
    });
    return data
}
export async function getOneDoc (collection, docId) {
    const docRef = doc(db, collection, docId);
    const docSnap = await getDoc(docRef);
    let item = null;
    if (docSnap.exists()) {
        item = {...docSnap.data(), id: docId};
    } else {
        console.log("No such document!");
    }
    return item
}
export async function getCollectionSortedByTimestamp(collectionName) {
    try {
        const q = query(collection(db, collectionName), orderBy('timestamp'));
        const querySnapshot = await getDocs(q);
        const sortedDocuments = [];
        querySnapshot.forEach((doc) => {
            sortedDocuments.push({ id: doc.id, ...doc.data() });
        });

        return sortedDocuments;
    } catch (error) {
        console.error('Ошибка при получении документов:', error);
        throw error;
    }
}