"use client"
import {useState} from "react";
import {usePathname} from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import MainImg from "@/components/elements/MainImg/MainImg";
import {toggleHireUsModal} from "@/helpers/toggleModals";
import './Header.scss'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    return (
        <header className='header'>
            <div className="header__box  section__wrapper">
                <Link href="/"><Image id="logoHeader" className="header__logo" src='/images/logo.svg' alt='AdKey.agency logo' width={120} height={38}/></Link>
                <div className={`nav__box ${isMenuOpen ? 'nav__box--active' : ''}`}>
                    <button className='nav__close' onClick={handleMenu}>
                        <Image id="closeMobMenuBtn" src='/images/close.svg' alt='close icon' width={32} height={32}/>
                    </button>
                    <nav>
                        <ul className="nav__list">
                            <li className="nav__item"><Link id="casesLink" href="/cases" onClick={handleMenu} className={`nav__link ${pathname === '/cases' ? 'accent' : ''}`}>Cases</Link></li>
                            <li className="nav__item"><Link id="careersLink"  href="/careers" onClick={handleMenu} className={`nav__link ${pathname === '/careers' ? 'accent' : ''}`}>Careers</Link></li>
                        </ul>
                    </nav>
                    <button id="headerHireUsBtn" onClick={toggleHireUsModal} className="nav__hire-btn">Hire us</button>
                    <div className="nav__img">
                        <MainImg/>
                    </div>
                </div>
                <button className="nav__menu-btn" onClick={handleMenu}><Image id="openMenuBtn" src='/images/menu.svg' alt='menu icon'
                                                                               width={32} height={32}/></button>
            </div>
        </header>
    )
}

export default Header;
